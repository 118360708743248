import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';
import TableauCard from './TableauCard';
import SkeletonTableauCard from './SkeletonTableauCard';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './@/components/ui/select';
import { Toggle } from './@/components/ui/toggle';
import { Button } from './@/components/ui/button';
import { Input } from './@/components/ui/input';
import { Separator } from "./@/components/ui/separator";
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from "./@/components/ui/drawer";
import { useTranslation } from 'react-i18next';

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

function Search() {
  const { t } = useTranslation();
  const [tableaux, setTableaux] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [userCounts, setUserCounts] = useState([]);
  const [sortOption, setSortOption] = useState('popularity');
  const [showOnlyCertified, setShowOnlyCertified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allTableaux, setAllTableaux] = useState([]);
  const [semester, setSemester] = useState('0');
  const [formations, setFormations] = useState(['Licence', 'Master', 'Doctorat', 'Ingénieur', 'BUT', 'Prépainté', 'PrépaCPGE', 'Bac']);
  const [formation, setFormation] = useState('0');
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  // Function to update URL with current filters
  const updateURLWithFilters = () => {
    const params = new URLSearchParams();
    if (sortOption) params.set('sort', sortOption);
    if (showOnlyCertified) params.set('certified', showOnlyCertified);
    if (semester !== '0') params.set('semester', semester);
    if (formation !== '0') params.set('formation', formation);
    if (searchQuery) params.set('query', searchQuery);
    navigate({ search: params.toString() });
  };

  // Read filters from URL on component mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sort = params.get('sort');
    const certified = params.get('certified');
    const sem = params.get('semester');
    const form = params.get('formation');
    const query = params.get('query');

    if (sort) setSortOption(sort);
    if (certified) setShowOnlyCertified(certified === 'true');
    if (sem) setSemester(sem);
    if (form) setFormation(form);
    if (query) setSearchQuery(query);
  }, [location.search]);

  useEffect(() => {
    const fetchTableaux = async () => {
      //console.log("server url :", process.env.REACT_APP_SERVER_URL);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/tableaux`);
      let counts = {};
      try {
        const countsArray = await Promise.all(response.data.map(async (tableau) => {
          const count = await getDistinctUserCount(tableau.idtableau);
          return { idtableau: tableau.idtableau, count };
        }));
        countsArray.forEach(({ idtableau, count }) => {
          counts[idtableau] = count;
        });
      } catch (error) {
        console.error(error);
      }
      setUserCounts(counts);
      setAllTableaux(response.data); // Store the entire fetched data
      const cacheData = {
        tableaux: response.data,
        userCounts: counts,
        timestamp: Date.now()
      };
      localStorage.setItem('tableauxCache', JSON.stringify(cacheData));
      setLoading(false);
    };

    const fetchFavorites = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/favoris`, {
          params: {
            idUtilisateur: localStorage.getItem('userId')
          }
        });
        setFavorites(response.data);
        const cacheData = {
          favorites: response.data,
          timestamp: Date.now()
        };
        localStorage.setItem('favoritesCache', JSON.stringify(cacheData));
      } catch (error) {
        console.error(error);
      }
    };

    const cachedTableaux = localStorage.getItem('tableauxCache');
    const cachedFavorites = localStorage.getItem('favoritesCache');

    if (cachedTableaux) {
      const { tableaux, userCounts, timestamp } = JSON.parse(cachedTableaux);
      if (Date.now() - timestamp < CACHE_DURATION) {
        //console.log('@@@@Using cached tableaux data@@@@');
        setAllTableaux(tableaux);
        setUserCounts(userCounts);
        setLoading(false);
      } else {
        //console.log('@@@@Tableaux cache expired, fetching new data@@@@');
        fetchTableaux();
      }
    } else {
      //console.log('@@@@Fetching tableaux data from server@@@@');
      fetchTableaux();
    }

    if (cachedFavorites) {
      const { favorites, timestamp } = JSON.parse(cachedFavorites);
      if (Date.now() - timestamp < CACHE_DURATION) {
        //console.log('@@@@Using cached favorites data@@@@');
        setFavorites(favorites);
      } else {
        //console.log('@@@@Favorites cache expired, fetching new data@@@@');
        fetchFavorites();
      }
    } else {
      //console.log('@@@@Fetching favorites data from server@@@@');
      fetchFavorites();
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      let sortedTableaux = [...allTableaux]; // Use the entire fetched data
      if (sortOption === 'popularity') {
        sortedTableaux.sort((a, b) => userCounts[b.idtableau] - userCounts[a.idtableau]);
      } else if (sortOption === 'recent') {
        sortedTableaux.sort((a, b) => b.idtableau - a.idtableau);
      }
      if (semester !== "0") {
        sortedTableaux = sortedTableaux.filter(tableau => tableau.semester === semester);
      }
      if (formation !== "0") {
        sortedTableaux = sortedTableaux.filter(tableau => tableau.formationlevel === formation);
      }
      if (searchQuery) {
        sortedTableaux = sortedTableaux.filter(tableau => tableau.nomtableau && tableau.nomtableau.toLowerCase().includes(searchQuery.toLowerCase()));
      }
      setTableaux(sortedTableaux);
      setFiltersApplied(sortOption !== 'popularity' || showOnlyCertified || semester !== '0' || formation !== '0' || searchQuery !== '');
      updateURLWithFilters(); // Update URL whenever filters change
    }
  }, [sortOption, showOnlyCertified, allTableaux, userCounts, loading, semester, formation, searchQuery]);

  async function getDistinctUserCount(tableauId) {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/count_distinct_users/${tableauId}`);
    return response.data.count;
  }

  const handleTableClick = (id) => {
    localStorage.removeItem('tableauxCache');
    localStorage.removeItem('favoritesCache');
    navigate('/tableau/' + id);
  };

  const resetFilters = () => {
    setSortOption('popularity');
    setShowOnlyCertified(false);
    setSemester('0');
    setFormation('0');
    setSearchQuery('');
  };

  return (
    <div className="flex w-full h-fit sm:h-full flex-col sm:flex-row">

      <div className="w-full sm:w-1/5 h-full pb-0 p-5 sm:p-5 flex flex-col justify-between gap-4">
        <div className="flex flex-col gap-4">

          <Input
            type="text"
            placeholder={t('search_table')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            focusOnLoad={true}
            className="hidden sm:flex"
          />

          <Select value={sortOption} onValueChange={(value) => setSortOption(value)}>
            <SelectTrigger className="w-full hidden sm:flex">
              <SelectValue placeholder={t('sort_by')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="popularity">{t('popularity')}</SelectItem>
              <SelectItem value="recent">{t('creation_date')}</SelectItem>
            </SelectContent>
          </Select>

          <Select value={semester} onValueChange={(value) => setSemester(value)}>
            <SelectTrigger className="w-full hidden sm:flex">
              <SelectValue placeholder={t('semester')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="0">{t('all_semesters')}</SelectItem>
              <SelectItem value="S1">{t('semester_1')}</SelectItem>
              <SelectItem value="S2">{t('semester_2')}</SelectItem>
              <SelectItem value="S3">{t('semester_3')}</SelectItem>
              <SelectItem value="S4">{t('semester_4')}</SelectItem>
              <SelectItem value="S5">{t('semester_5')}</SelectItem>
              <SelectItem value="S6">{t('semester_6')}</SelectItem>
              <SelectItem value="S7">{t('semester_7')}</SelectItem>
              <SelectItem value="S8">{t('semester_8')}</SelectItem>
              <SelectItem value="S9">{t('semester_9')}</SelectItem>
              <SelectItem value="S10">{t('semester_10')}</SelectItem>
            </SelectContent>
          </Select>

          <Select value={formation} onValueChange={(value) => setFormation(value)}>
            <SelectTrigger className="w-full hidden sm:flex">
              <SelectValue placeholder={t('formation')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="0">{t('all_formations')}</SelectItem>
              {formations.map((formation, index) => (
                <SelectItem key={index} value={formation}>{formation}</SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Toggle id="show-only-certified" pressed={showOnlyCertified}
            onPressedChange={(pressed) => setShowOnlyCertified(pressed)} className="hidden sm:flex">
            {
              showOnlyCertified ? (
                <i className="fas fa-check-circle text-blue-500 mr-1 dark:text-blue-500"></i>
              ) : (
                <i className="far fa-circle text-gray-500 mr-1 dark:text-gray-500"></i>
              )
            }
            <p htmlFor="show-only-certified">{t('only_certified')}</p>
          </Toggle>

          {filtersApplied && (
            <Button onClick={resetFilters} className="mt-4 hidden sm:flex">
              <i className="fas fa-undo mr-2"></i>
              {t('reset_filters')}
            </Button>
          )}

          <Drawer>
            <div className="flex flex-row gap-4">
              <Input
                type="text"
                placeholder={t('search_table')}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                focusOnLoad={true}
                className="block sm:hidden w-full"
              />
              <DrawerTrigger className="w-fit sm:hidden !m-0 !p-0">
                <Button variant="outline">
                  <i className="fas fa-filter mr-2"></i>
                  {t('filters')}
                </Button>
              </DrawerTrigger>
            </div>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>{t('advanced_filters')}</DrawerTitle>
                <DrawerDescription>{t('apply_filters')}</DrawerDescription>
              </DrawerHeader>
              <DrawerFooter>

                <Select value={sortOption} onValueChange={(value) => setSortOption(value)} className="hidden sm:block">
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={t('sort_by')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="popularity">{t('popularity')}</SelectItem>
                    <SelectItem value="recent">{t('creation_date')}</SelectItem>
                  </SelectContent>
                </Select>

                <Select value={semester} onValueChange={(value) => setSemester(value)} className="hidden sm:block">
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={t('semester')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="0">{t('all_semesters')}</SelectItem>
                    <SelectItem value="S1">{t('semester_1')}</SelectItem>
                    <SelectItem value="S2">{t('semester_2')}</SelectItem>
                    <SelectItem value="S3">{t('semester_3')}</SelectItem>
                    <SelectItem value="S4">{t('semester_4')}</SelectItem>
                    <SelectItem value="S5">{t('semester_5')}</SelectItem>
                    <SelectItem value="S6">{t('semester_6')}</SelectItem>
                    <SelectItem value="S7">{t('semester_7')}</SelectItem>
                    <SelectItem value="S8">{t('semester_8')}</SelectItem>
                    <SelectItem value="S9">{t('semester_9')}</SelectItem>
                    <SelectItem value="S10">{t('semester_10')}</SelectItem>
                  </SelectContent>
                </Select>

                <Select value={formation} onValueChange={(value) => setFormation(value)} className="hidden sm:block">
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={t('formation')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="0">{t('all_formations')}</SelectItem>
                    {formations.map((formation, index) => (
                      <SelectItem key={index} value={formation}>{formation}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Toggle id="show-only-certified" pressed={showOnlyCertified}
                  onPressedChange={(pressed) => setShowOnlyCertified(pressed)}>
                  {
                    showOnlyCertified ? (
                      <i className="fas fa-check-circle text-blue-500 mr-1 dark:text-blue-500"></i>
                    ) : (
                      <i className="far fa-circle text-gray-500 mr-1 dark:text-gray-500"></i>
                    )
                  }
                  <p htmlFor="show-only-certified">{t('only_certified')}</p>
                </Toggle>

                {filtersApplied && (
                  <Button onClick={resetFilters} className="mt-4">
                    <i className="fas fa-undo mr-2"></i>
                    {t('reset_filters')}
                  </Button>
                )}

                <DrawerClose className="!m-0 !p-0">
                  <Button variant="outline" className="w-full">
                    <i className="fas fa-check mr-2"></i>
                    {t('apply_filters')}
                  </Button>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>

        </div>

        <div
          className="hidden sm:inline text-base border bg-blue-50 text-blue-500 dark:bg-blue-900 dark:text-blue-50 p-3 rounded-lg border-blue-500">
          <p><i className="fas fa-info-circle mr-2"></i>{t('certified_info_1')}</p>
          <p>{t('certified_info_2')}</p>
        </div>

      </div>

      <Separator orientation="vertical" className="h-full border-r border--muted" />

      <div
        className="w-full sm:w-4/5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1 p-3 !h-fit">
        {loading ? (
          Array.from({ length: 30 }).map((_, index) => (
            <SkeletonTableauCard key={index} />
          ))
        ) : (
          tableaux.length === 0 ? (
            <div className="flex flex-col items-center justify-center w-full h-20 m-auto mt-5">
              <i className="fas fa-search text-4xl text-gray-500 mb-4"></i>
              <p className="text-gray-500">{t('no_table_found')}</p>
            </div>
          ) : (
            tableaux.filter(tableau => !showOnlyCertified || tableau.iscertified).map((tableau, index) => (
              <div key={index}>
                <TableauCard
                  tableau={tableau}
                  favorite={favorites.some(favorite => favorite.idtableau === tableau.idtableau)}
                  userCount={userCounts[tableau.idtableau]}
                  onClick={() => handleTableClick(tableau.idtableau)}
                  className={`relative tableau hover:shadow-md opacity-0 transition duration-200 ease-in-out animate-fadeIn select-none hover:cursor-pointer ${tableau.iscertified ? 'border-blue-500 dark:border-blue-500 bg-blue-50 dark:bg-blue-900' : ''}`}
                  style={{ animationDelay: `${index * 0.1}s` }}
                  isAdmin={isAdmin}
                />
              </div>
            ))
          )
        )}
      </div>
    </div>
  );
}

export default Search;