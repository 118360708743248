import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "./@/components/ui/card";
import {Button} from "./@/components/ui/button";
import {Label} from "./@/components/ui/label";
import {Input} from "./@/components/ui/input";

function Profile() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token'); // Replace with the actual method to get the token

      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/profile/${localStorage.getItem('userId')}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setFirstName(response.data.prenomutilisateur);
        setLastName(response.data.nomutilisateur);
        setUsername(response.data.login);
        setEmail(response.data.mailutilisateur);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUser();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userId = localStorage.getItem('userId'); // Retrieve the user ID from local storage

    console.log("First Name: ", firstName, "Last Name: ", lastName, "User ID: ", userId);
    try {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/profile/${userId}`, {
        firstName,
        lastName,
        userId
      });

      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error('An error occurred while updating the profile');
    }
  };

  return (
    <Card className="m-auto w-3/4 sm:w-1/2 md:w-1/3">
      <CardHeader>
        <CardTitle>Mettre à jour le profil</CardTitle>
      </CardHeader>

      <form onSubmit={handleSubmit}>

        <CardContent>
          <Label>
            Nom d'utilisateur:
            <Input type="text" value={username} readOnly/>
          </Label>
          <Label>
            Email:
            <Input type="email" value={email} readOnly/>
          </Label>
          <Label>
            Prénom:
            <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
          </Label>
          <Label>
            Nom:
            <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
          </Label>
        </CardContent>

        <CardFooter>
          <Button className="w-full" type="submit">Mettre à jour</Button>
        </CardFooter>
      </form>
    </Card>
  );
}

export default Profile;