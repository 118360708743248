import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import i18n from './i18n';
import {BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import LandingPage from './LandingPage';
import Login from './Login';
import Register from './Register';
import Search from './Search';
import Dashboard from './Dashboard';
import Profile from './Profile';
import CreateTableau from "./CreateTableau";
import TableauPage from "./TableauPage";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BillingPlanPage from "./BillingPlanPage";
import {Button} from "@/components/ui/button";
import {Drawer, DrawerContent, DrawerHeader, DrawerTrigger} from "@/components/ui/drawer";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
  NavigationMenuContent,
  NavigationMenuTrigger,
  navigationMenuTriggerStyleVariants
} from "./@/components/ui/navigation-menu";
import {Separator} from "./@/components/ui/separator";
import axios from "axios";
import AdminPage from "./AdminPage";
import Verify from "./Verify";
import {ThemeSwitcherButton} from "./CuiCui/ThemeSwitcherButton";
import {LanguageSwitcherButton} from "./CuiCui/LanguageSwitcherButton";

function MainContent() {
  const {t} = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [theme, setTheme] = useState('light');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      document.documentElement.classList.add('dark');
      localStorage.theme = 'dark';
    } else {
      setTheme('light');
      document.documentElement.classList.remove('dark');
      localStorage.theme = 'light';
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      const checkAdmin = async () => {
        try {
          if (localStorage.getItem('userId')) {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/${localStorage.getItem('userId')}`);
            setIsAdmin(response.data.isAdmin);
            localStorage.setItem('isAdmin', response.data.isAdmin);
          } else {
            console.error('No user id found');
          }
        } catch (error) {
          console.error('Error checking admin status:', error);
        }
      };
      if (localStorage.getItem('userId')) {
        if (localStorage.getItem('isAdmin') === 'true') {
          setIsAdmin(true);
        } else {
          checkAdmin().then(r => r);
        }
      }
    }
    const theme = localStorage.getItem('theme');
    if (theme) {
      setTheme(theme);
      if (theme === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('dark');
      document.documentElement.classList.add('dark');
    }
  }, []);

  const handleLogout = () => {
    setIsOpen(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.clear();
    setIsLoggedIn(false);
    navigate('/');
  };

  function PrivateRoute({children}) {
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
      }
      setLoading(false);
    }, []);

    if (loading) {
      return null;
    }

    if (!isLoggedIn) {
      toast.error('Vous devez être connecté(e) pour accéder à cette page');
      return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return children;
  }

  return (
    <>
      {location.pathname !== '/register' && (
        <div className="w-full z-20">
          <div
            className="navbar select-none flex flex-row w-full backdrop-blur gap-2 justify-between mx-auto {classnames('App', { 'bg-white': theme === 'light', 'bg-black': theme === 'dark' })}">
            <NavigationMenu className="!w-full">
              <NavigationMenuList className="flex gap-2 justify-between !w-screen p-2">
                <NavigationMenuItem>
                  <Link to="/">
                    <NavigationMenuLink
                      className={`${navigationMenuTriggerStyle()} flex items-center gap-2 transition`}>
                      <i className="fas fa-chart-line"></i>
                      <h1 className="font-bold select-text">ImproveGrade.com</h1>
                    </NavigationMenuLink>
                  </Link>
                </NavigationMenuItem>
                <NavigationMenuItem className="hidden bg-yellow-200 dark:bg-yellow-500 dark:text-yellow-900">
                  <NavigationMenuLink
                    className={`${navigationMenuTriggerStyle()} flex items-center gap-2 transition`}>
                    <i className="fas fa-person-digging"></i>
                    <h1 className="font-bold select-text">Site en cours de développement</h1>
                  </NavigationMenuLink>
                </NavigationMenuItem>
                <Drawer open={isOpen} onOpenChange={setIsOpen} className="hidden sm:block">
                  <DrawerTrigger className="block sm:hidden">
                    <i className="fas fa-bars"></i>
                  </DrawerTrigger>
                  <DrawerContent className="bg-background">
                    <DrawerHeader className="flex flex-col gap-2">
                      <Link to={'/search'} onClick={() => setIsOpen(false)}>
                        <Button className="w-full" variant="outline">
                          <i className="fas fa-search mr-2"></i>
                          {t('search')}
                        </Button>
                      </Link>
                      {isLoggedIn && isAdmin && (
                        <Link to="/admin" onClick={() => setIsOpen(false)}>
                          <Button className="w-full" variant="outline">
                            <i className="fas fa-user-cog mr-2"></i>
                            {t('admin')}
                          </Button>
                        </Link>
                      )}
                      {isLoggedIn ? (
                        <>
                          <Link to="/creation" onClick={() => setIsOpen(false)}>
                            <Button className="w-full" variant="outline">
                              <i className="fas fa-plus mr-2"></i>
                              {t('create')}
                            </Button>
                          </Link>
                          <Link to={`/profile`} onClick={() => setIsOpen(false)}>
                            <Button className="w-full" variant="outline">
                              <i className="fas fa-user mr-2"></i>
                              {t('profile')}
                            </Button>
                          </Link>
                          <Link to={`/dashboard`} onClick={() => setIsOpen(false)}>
                            <Button className="w-full" variant="outline">
                              <i className="fas fa-tachometer-alt mr-2"></i>
                              {t('dashboard')}
                            </Button>
                          </Link>
                          <Link to="/premium" onClick={() => setIsOpen(false)} className="hidden">
                            <Button className="w-full" variant="outline">
                              <i className="fas fa-star mr-2"></i>
                              {t('premium')}
                            </Button>
                          </Link>
                          <Link onClick={handleLogout} to={'/'}>
                            <Button className="w-full" variant="destructive">
                              <i className="fas fa-sign-out-alt mr-2"></i>
                              {t('logout')}
                            </Button>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to="/login" onClick={() => setIsOpen(false)}>
                            <Button className="w-full">
                              <i className="fas fa-sign-in-alt mr-2"></i>
                              {t('login')}
                            </Button>
                          </Link>
                          <Link to="/register" onClick={() => setIsOpen(false)}>
                            <Button className="w-full">
                              <i className="fas fa-user-plus mr-2"></i>
                              {t('register')}
                            </Button>
                          </Link>
                        </>
                      )}
                    </DrawerHeader>
                  </DrawerContent>
                </Drawer>
                <div className="sm:flex gap-2 justify-end font-bold hidden">
                  <NavigationMenuItem>
                    <Link to="/search">
                      <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                        <i className="fas fa-search mr-2"></i>
                        {t('search')}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                  {isLoggedIn && isAdmin && (
                    <NavigationMenuItem>
                      <Link to="/admin">
                        <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                          <i className="fas fa-user-cog mr-2"></i>
                          {t('admin')}
                        </NavigationMenuLink>
                      </Link>
                    </NavigationMenuItem>
                  )}
                  {isLoggedIn ? (
                    <>
                      <NavigationMenuItem>
                        <Link to="/creation">
                          <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                            <i className="fas fa-plus mr-2"></i>
                            {t('create')}
                          </NavigationMenuLink>
                        </Link>
                      </NavigationMenuItem>
                      <NavigationMenuItem>
                        <NavigationMenuTrigger>
                          <i className="fas fa-user mr-2"></i>
                          {t('profile')}
                        </NavigationMenuTrigger>
                        <NavigationMenuContent id="plazza" className="!z-[1000]">
                          <ul className="grid gap-1 p-2">
                            <li className="w-full">
                              <NavigationMenuLink asChild className={navigationMenuTriggerStyle()}>
                                <Link to={`/profile`}>
                                  <i className="fas fa-user mr-2"></i>
                                  {t('profile')}
                                </Link>
                              </NavigationMenuLink>
                            </li>
                            <li>
                              <NavigationMenuLink asChild className={navigationMenuTriggerStyle()}>
                                <Link to={`/dashboard`}>
                                  <i className="fas fa-tachometer-alt mr-2"></i>
                                  {t('dashboard')}
                                </Link>
                              </NavigationMenuLink>
                            </li>
                            <li className="hidden">
                              <NavigationMenuItem>
                                <Link to="/premium">
                                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                                    <i className="fas fa-star mr-2"></i>
                                    {t('premium')}
                                  </NavigationMenuLink>
                                </Link>
                              </NavigationMenuItem>
                            </li>
                            <li>
                              <NavigationMenuLink asChild className={navigationMenuTriggerStyle()}>
                                <Link onClick={handleLogout} to={'/'}
                                      className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300">
                                  <i className="fas fa-sign-out-alt mr-2"></i>
                                  {t('logout')}
                                </Link>
                              </NavigationMenuLink>
                            </li>
                          </ul>
                        </NavigationMenuContent>
                      </NavigationMenuItem>
                    </>
                  ) : (
                    <>
                      <NavigationMenuItem>
                        <Link to="/login">
                          <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                            <i className="fas fa-sign-in-alt mr-2"></i>
                            {t('login')}
                          </NavigationMenuLink>
                        </Link>
                      </NavigationMenuItem>
                      <NavigationMenuItem>
                        <Link to="/register">
                          <NavigationMenuLink className={navigationMenuTriggerStyleVariants()}>
                            <i className="fas fa-user-plus mr-2"></i>
                            {t('register')}
                          </NavigationMenuLink>
                        </Link>
                      </NavigationMenuItem>
                    </>
                  )}
                  <NavigationMenuItem className="flex items-center justify-center pr-1">
                    <LanguageSwitcherButton/>
                  </NavigationMenuItem>
                </div>
              </NavigationMenuList>
            </NavigationMenu>
          </div>
          <Separator orientation="horizontal" className="w-full border-t border--muted"/>
        </div>
      )}
      <div className="h-full flex z-0">
        <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/login" element={<Login onLogin={() => setIsLoggedIn(true)}/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/verify" element={<Verify/>}/>
          <Route path="/premium" element={<BillingPlanPage/>}/>
          <Route path="/tableau/:id" element={<TableauPage/>}/>
          <Route path="/creation" element={<PrivateRoute><CreateTableau/></PrivateRoute>}/>
          <Route path="/search" element={<Search/>}/>
          <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
          <Route path="/dashboard" element={<PrivateRoute><Dashboard onLogout={handleLogout}/></PrivateRoute>}/>
          <Route path="/admin" element={<PrivateRoute><AdminPage onLogout={handleLogout}/></PrivateRoute>}/>
        </Routes>
      </div>
      {location.pathname !== '/register' && (
        <footer className="bg-background text-text w-full hidden sm:inline">
          <Separator orientation="horizontal" className="w-full border-t border--muted"/>
          <div className="w-full h-16 flex justify-between items-center px-3.5">
            <p className="text-center ml-2">
              {t('footer_developed_by')} <a href="https://bento.me/soren-starck" target="_blank" rel="noreferrer"
                                            className="underline">
              Soren STARCK
            </a>
            </p>
            <p className="text-center">© 2024 ImproveGrade.com</p>
            <p className="hidden">
              {t('footer_thanks_to')} Norman FRANÇOIS
            </p>
            <button onClick={toggleTheme} className="p-0">
              <ThemeSwitcherButton/>
            </button>
          </div>
        </footer>
      )}
      <ToastContainer/>
    </>
  );
}

function App() {
  return (
    <Router>
      <MainContent/>
    </Router>
  );
}

export default App;