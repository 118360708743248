// In `SkeletonTableauCard.js`
import React from 'react';
import { Skeleton } from "./@/components/ui/skeleton";

function SkeletonTableauCard() {
  return (
    <div className="m-2 p-2 overflow-hidden h-min text-sm border border--muted rounded-lg -z-10">
      <div className="p-1">
        <Skeleton className="h-4 w-3/4 mb-2" />
      </div>
      <div className="p-1 flex flex-row justify-between">
        <div className="flex flex-col gap-1">
          <Skeleton className="h-4 w-20" />
          <Skeleton className="h-4 w-14" />
        </div>
        <div className="flex flex-col justify-end gap-1 ml-auto">
          <Skeleton className="h-4 w-10" />
          <Skeleton className="h-4 w-8" />
        </div>
      </div>
    </div>
  );
}

export default SkeletonTableauCard;