import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import exempleA from './assets/exempleA.png';
import { useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./@/components/ui/card";
import { Button } from "./@/components/ui/button";
import { Label } from "./@/components/ui/label";
import { Input } from "./@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./@/components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./@/components/ui/tabs";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./@/components/ui/dialog";
import { Checkbox } from "./@/components/ui/checkbox";

function CreateTableau() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const idutilisateur = localStorage.getItem('userId');
  const [name, setName] = useState('');
  const [semester, setSemester] = useState('S1');
  const [formationLevel, setFormationLevel] = useState('Master');
  const [resources, setResources] = useState('8');
  const [ue, setUe] = useState('4');
  const [showSecondContainer, setShowSecondContainer] = useState(false);
  const [coefficients, setCoefficients] = useState([]);

  const [invalidRows, setInvalidRows] = useState([]);
  const [invalidColumns, setInvalidColumns] = useState([]);

  const [tab, setTab] = useState('firstContainer');

  const onTabChange = (tab) => {
    setTab(tab);
  }

  const checkValidity = () => {
    const ueArray = [];
    const resourceArray = [];
    console.log('Checking validity...', ueArray, resourceArray);
    for (let i = 1; i < parseInt(resources) + 1; i++) {
      const resourceNameElement = document.querySelector(`table tr:nth-child(${i + 1}) td:nth-child(1) input`);
      if (resourceNameElement) {
        resourceArray.push({ resourceName: resourceNameElement.value, resourceNumber: i });
      }
    }
    for (let i = 1; i < parseInt(ue + 1); i++) {
      const ueNameElement = document.querySelector(`table tr:nth-child(1) td:nth-child(${i + 1}) input`);
      if (ueNameElement) {
        ueArray.push({ ueName: ueNameElement.value, ueNumber: i });
      }
      for (let j = 1; j < parseInt(resources + 1); j++) {
        const inputElement = document.querySelector(`table tr:nth-child(${j + 1}) td:nth-child(${i + 1}) input`);
        const resourceNameElement = document.querySelector(`table tr:nth-child(${j + 1}) td:nth-child(1) input`);
        if (inputElement && inputElement.value && resourceNameElement) {
          console.log(`${ueNameElement.value} - ${resourceNameElement.value}: ${inputElement.value}`);
          coefficients.push({
            coefficient: inputElement.value,
            ueNumber: i,
            resourceNumber: j
          });
        }
      }
    }
    const allUesInCoefficients = ueArray.every(ue => coefficients.some(c => c.ueNumber === ue.ueNumber));
    const allResourcesInCoefficients = resourceArray.every(resource => coefficients.some(c => c.resourceNumber === resource.resourceNumber));

    if (!allUesInCoefficients || !allResourcesInCoefficients) {
      const invalidRowsTemp = [];
      const invalidColumnsTemp = [];

      for (let i = 1; i < parseInt(resources) + 1; i++) {
        const resourceNameElement = document.querySelector(`table tr:nth-child(${i + 1}) td:nth-child(1) input`);
        if (resourceNameElement && !coefficients.some(c => c.resourceNumber === i)) {
          invalidRowsTemp.push(i);
        }
      }

      for (let i = 1; i < parseInt(ue) + 1; i++) {
        const ueNameElement = document.querySelector(`table tr:nth-child(1) td:nth-child(${i + 1}) input`);
        if (ueNameElement && !coefficients.some(c => c.ueNumber === i)) {
          invalidColumnsTemp.push(i);
        }
      }
      console.log('Invalid rows:', invalidRowsTemp);
      console.log('Invalid columns:', invalidColumnsTemp);

      if (invalidRowsTemp.length > 0 || invalidColumnsTemp.length > 0) {
        setInvalidRows(invalidRowsTemp);
        setInvalidColumns(invalidColumnsTemp);
        return;
      }
      return;
    }
  };

  const handleFirstSubmit = (event) => {
    event.preventDefault();
    console.log(`Number of rows (resources + 1): ${parseInt(resources) + 1}`);
    console.log(`Number of columns (ue + 1): ${parseInt(ue) + 1}`);
    setShowSecondContainer(true);
    setTab('table');
  };

  const handleSecondSubmit = async (event) => {
    event.preventDefault();
    const env = "dev"
    const ueArray = [];
    const resourceArray = [];
    coefficients.length = 0;
    for (let i = 1; i < parseInt(resources) + 1; i++) {
      const resourceNameElement = document.querySelector(`table tr:nth-child(${i + 1}) td:nth-child(1) input`);
      if (resourceNameElement) {
        resourceArray.push({ resourceName: resourceNameElement.value, resourceNumber: i });
      }
    }
    for (let i = 1; i < parseInt(ue + 1); i++) {
      const ueNameElement = document.querySelector(`table tr:nth-child(1) td:nth-child(${i + 1}) input`);
      if (ueNameElement) {
        ueArray.push({ ueName: ueNameElement.value, ueNumber: i });
      }
      for (let j = 1; j < parseInt(resources + 1); j++) {
        const inputElement = document.querySelector(`table tr:nth-child(${j + 1}) td:nth-child(${i + 1}) input`);
        const resourceNameElement = document.querySelector(`table tr:nth-child(${j + 1}) td:nth-child(1) input`);
        if (inputElement && inputElement.value && resourceNameElement) {
          console.log(`${ueNameElement.value} - ${resourceNameElement.value}: ${inputElement.value}`);
          coefficients.push({
            coefficient: inputElement.value,
            ueNumber: i,
            resourceNumber: j
          });
        }
      }
    }
    if (env === "devn") {
      console.log({
        idutilisateur,
        name,
        semester,
        formationLevel,
        resources,
        ue,
        coefficients,
        ueArray,
        resourceArray
      });
    } else {
      const allUesInCoefficients = ueArray.every(ue => coefficients.some(c => c.ueNumber === ue.ueNumber));
      const allResourcesInCoefficients = resourceArray.every(resource => coefficients.some(c => c.resourceNumber === resource.resourceNumber));

      if (!allUesInCoefficients || !allResourcesInCoefficients) {
        alert(t('fill_all_fields'));
        const invalidRowsTemp = [];
        const invalidColumnsTemp = [];

        for (let i = 1; i < parseInt(resources) + 1; i++) {
          const resourceNameElement = document.querySelector(`table tr:nth-child(${i + 1}) td:nth-child(1) input`);
          if (resourceNameElement && !coefficients.some(c => c.resourceNumber === i)) {
            invalidRowsTemp.push(i);
          }
        }

        for (let i = 1; i < parseInt(ue) + 1; i++) {
          const ueNameElement = document.querySelector(`table tr:nth-child(1) td:nth-child(${i + 1}) input`);
          if (ueNameElement && !coefficients.some(c => c.ueNumber === i)) {
            invalidColumnsTemp.push(i);
          }
        }

        if (invalidRowsTemp.length > 0 || invalidColumnsTemp.length > 0) {
          setInvalidRows(invalidRowsTemp);
          setInvalidColumns(invalidColumnsTemp);
          alert(t('check_inputs'));
          return;
        }
        return;
      }
      console.log("Sending data to the server...");
      try {
        console.log("Sent :", {
          idutilisateur,
          name,
          semester,
          formationLevel,
          resources,
          ue,
          coefficients,
          ueArray,
          resourceArray
        });
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/tableaux`, {
          idutilisateur,
          name,
          semester,
          formationLevel,
          resources,
          ue,
          coefficients,
          ueArray,
          resourceArray
        });
        console.log("Received :", response.data);
        localStorage.removeItem('tableauxCache');
        localStorage.removeItem('favoritesCache');
        navigate('/tableau/' + encodeURIComponent(CryptoJS.AES.encrypt(response.data.idtableau.toString(), 'secret-key').toString()));
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    checkValidity();
  }, [coefficients, resources, ue]);

  return (
    <div className="w-full sm:p-10 sm:m-10">

      <Tabs defaultValue="firstContainer" className="w-full" value={tab}>
        <TabsList>
          <TabsTrigger value="firstContainer">
            {t('specifications')}</TabsTrigger>
          <TabsTrigger value="table" disabled={!showSecondContainer}
                       id="second-tab"
                       onClick={() => onTabChange('table')}
                       className={showSecondContainer ? 'cursor-pointer' : 'cursor-not-allowed'}
                       title={showSecondContainer ? '' : t('fill_specifications')}>
            {t('coefficient_table')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="firstContainer">

          <Card>
            <CardHeader>
              <CardTitle>{t('create_table')}</CardTitle>
              <CardDescription>{t('enter_specifications')}</CardDescription>
            </CardHeader>
            <form onSubmit={handleFirstSubmit}>
              <CardContent className="space-y-2">

                <div className="space-y-1">
                  <Label>{t('formation_theme')}:</Label>
                  <Input type="text" value={name} placeholder={t('formation_theme_placeholder')}
                         onChange={e => setName(e.target.value)} required/>
                </div>

                <div className="space-y-1">
                  <Label>{t('semester')}:</Label>
                  <Select value={semester} onValueChange={setSemester} required>
                    <SelectTrigger>
                      <SelectValue placeholder={t('semester')}/>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="S1">S1</SelectItem>
                      <SelectItem value="S2">S2</SelectItem>
                      <SelectItem value="S3">S3</SelectItem>
                      <SelectItem value="S4">S4</SelectItem>
                      <SelectItem value="S5">S5</SelectItem>
                      <SelectItem value="S6">S6</SelectItem>
                      <SelectItem value="S7">S7</SelectItem>
                      <SelectItem value="S8">S8</SelectItem>
                      <SelectItem value="S9">S9</SelectItem>
                      <SelectItem value="S10">S10</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-1">
                  <Label>{t('formation_type')}:</Label>
                  <Select value={formationLevel} onValueChange={setFormationLevel} required>
                    <SelectTrigger>
                      <SelectValue placeholder={t('formation_type')}/>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Licence">{t('license')}</SelectItem>
                      <SelectItem value="Master">{t('master')}</SelectItem>
                      <SelectItem value="Doctorat">{t('doctorate')}</SelectItem>
                      <SelectItem value="Ingénieur">{t('engineer')}</SelectItem>
                      <SelectItem value="BUT">{t('but')}</SelectItem>
                      <SelectItem value="Prépainté">{t('integrated_prep')}</SelectItem>
                      <SelectItem value="PrépaCPGE">{t('cpge_prep')}</SelectItem>
                      <SelectItem value="Bac">{t('bac')}</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-1">
                  <Label>{t('number_of_resources')}:</Label>
                  <Input type="number" min="1" max="20" value={resources}
                         onChange={e => setResources(e.target.value)}/>
                </div>

                <div className="space-y-1">
                  <Label>{t('number_of_ues')}:</Label>
                  <Input type="number" min="1" max="10" value={ue} onChange={e => setUe(e.target.value)}/>
                </div>

              </CardContent>
              <CardFooter>
                <Button type="submit" className="w-full">
                  {t('validate_specifications')}
                </Button>
              </CardFooter>
            </form>
          </Card>
        </TabsContent>

        <TabsContent value="table">
          <Card className="m-auto w-full">
            <CardHeader>
              <CardTitle>{t('coefficient_table')}</CardTitle>
              <CardDescription>
                {t('enter_coefficients')}
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="link">
                      {t('see_example')}
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogTitle>{t('example_table')}</DialogTitle>
                    <DialogDescription className={"space-y-2"}>
                      <p>{t('example_table_description')}</p>
                      <img src={exempleA} alt={t('example_table_alt')}/>

                      <p>{t('example_table_note')}</p>

                      <table>
                        <tr>
                          <td></td>
                          <td>{t('ue')} n°1</td>
                          <td>{t('ue')} n°2</td>
                          <td>{t('ue')} n°3</td>
                        </tr>
                        <tr title={t('enter_resource_name_and_coefficients')}>
                          <td>{t('mathematics')}</td>
                          <td>60</td>
                          <td>80</td>
                          <td></td>
                        </tr>
                        <tr title={t('enter_resource_name_and_coefficients')}>
                          <td>{t('geography')}</td>
                          <td>30</td>
                          <td></td>
                          <td>100</td>
                        </tr>
                        <tr title={t('enter_resource_name_and_coefficients')}>
                          <td>{t('english')}</td>
                          <td>10</td>
                          <td>20</td>
                          <td></td>
                        </tr>
                      </table>

                      <p>{t('example_table_observation')}</p>
                    </DialogDescription>
                    <DialogFooter>

                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </CardDescription>
            </CardHeader>
            <form onSubmit={handleSecondSubmit}>
              <CardContent>
                <table>
                  {[...Array(parseInt(resources) + 1)].map((_, rowIndex) => (
                    <tr key={rowIndex}
                        className={invalidRows.includes(rowIndex) ? '!border-none border-2 border-red-300 animate-pulse' : ''}>
                      {[...Array(parseInt(ue) + 1)].map((_, colIndex) => (
                        <td key={colIndex}
                            className={invalidColumns.includes(colIndex) ? '!border-none border-2 border-red-300 animate-pulse' : ''}>
                          {rowIndex === 0 && colIndex === 0 ? (
                            <p className="text-center"></p>
                          ) : rowIndex === 0 && colIndex > 0 ? (
                            <Input type="text" defaultValue={`${t('ue')}${colIndex}`}
                                   className="rounded-none shadow-none !border-none !ring-transparent"/>
                          ) : colIndex === 0 && rowIndex > 0 ? (
                            <Input type="text" defaultValue={`${t('resource')}${rowIndex}`}
                                   className="rounded-none shadow-none !border-none !ring-transparent"/>
                          ) : (
                            <Input type="number" inputmode="numeric"
                                   className="rounded-none shadow-none !border-none !ring-transparent"/>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </table>
              </CardContent>
              <CardFooter>
                <Dialog>
                  <DialogTrigger>
                    <Button className={"w-full"}>
                      {t('create_table')}
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>{t('confirmation')}</DialogTitle>
                      <DialogDescription>
                        {t('confirmation_description')}
                      </DialogDescription>
                      <Button type="submit" className="w-full">
                        {t('create_table')}
                      </Button>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </CardFooter>
            </form>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default CreateTableau;