import React from 'react';

const BillingPlansPage = () => {
  return (
    <div className="h-full w-full flex flex-col">

      <div className="h-1/2 w-full flex flex-grid items-center justify-center p-20 gap-10">
        <div className="container h-full">
          <div className="flex flex-row items-center justify-between gap-4 text-2xl">
            <h1>
              Compte découverte
            </h1>
            <span className="badge">
            0€/mois
          </span>
          </div>
          <div>
            Ce compte est gratuit et vous permet de découvrir les fonctionnalités de Jupiter.
          </div>
        </div>

        <div className="container h-full">
          <div className="flex flex-row items-center justify-between gap-4 text-2xl">
            <h1>
              Compte créateur
            </h1>
            <span className="badge">
            0.99€/mois
          </span>
          </div>
          <div>
            Ce compte vous permet de créer vos propres tableaux et d'accéder à 6 tableaux. Il est recommandé pour les étudiants investis.
          </div>
        </div>

        <div className="container h-full">
          <div className="flex flex-row items-center justify-between gap-4 text-2xl">
            <h1>
              Compte premium
            </h1>
            <span className="badge">
            3.99€/mois
          </span>
          </div>
          <div>
            Ce compte vous permet de créer un nombre illimité de tableaux et d'accéder à un support prioritaire. Toutes les fonctionnalités de Jupiter sont accessibles de manière illimitée.
          </div>
        </div>
      </div>

      <div className="h-1/2 w-full p-20">
        <div className="container !w-full h-full flex flex-col items-center justify-center">
          <table className="w-full billing-table">
            <thead>
            <tr>
              <th>Fonctionnalités</th>
              <th className="bg-gray-200 text-gray-800">
                Compte découverte
              </th>
              <th className="bg-green-200 text-green-800">
                Compte créateur
              </th>
              <th className="bg-purple-200 text-purple-800">
                Compte premium
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Accès aux tableaux</td>
              <td className="bg-gray-100">1</td>
              <td className="bg-green-100">6</td>
              <td className="bg-purple-100">Illimité</td>
            </tr>
            <tr>
              <td>Favoris</td>
              <td className="bg-gray-100">1</td>
              <td className="bg-green-100">10</td>
              <td className="bg-purple-100">Illimité</td>
            </tr>
            <tr>
              <td>Support</td>
              <td className="bg-gray-100">Standard</td>
              <td className="bg-green-100">Prioritaire</td>
              <td className="bg-purple-100">Prioritaire</td>
            </tr>
            <tr>
              <td>Création de tableaux</td>
              <td>-</td>
              <td className="bg-green-100">3</td>
              <td className="bg-purple-100">Illimité</td>
            </tr>
            <tr>
              <td>Certification des tableaux</td>
              <td>-</td>
              <td className="bg-green-100">Sur demande</td>
              <td className="bg-purple-100">
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Accés au rang pour chaque tableau</td>
              <td>-</td>
              <td className="bg-green-100">
                <i className="fas fa-check"></i>
              </td>
              <td className="bg-purple-100">
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Assistant IA personnel</td>
              <td>-</td>
              <td>-</td>
              <td className="bg-purple-100">
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Recommandations personnalisées</td>
              <td>-</td>
              <td>-</td>
              <td className="bg-purple-100">
                <i className="fas fa-check"></i>
              </td>
            </tr>
            <tr>
              <td>Exportation des tableaux</td>
              <td>-</td>
              <td>-</td>
              <td className="bg-purple-100">
                <i className="fas fa-check"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BillingPlansPage;